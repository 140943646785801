import { navigate } from "gatsby";
import React, { useState } from "react";
import {
  RealmAppProvider,
  OnboardingRedirector,
  APP_ID,
} from "../../../components/realm_app";
import OnboardingNavbar from "../../../components/onboarding_navbar";
import { Footer } from "../../../components/footer";

import "react-phone-input-2/lib/style.css";

import { MentorshipDescrInput } from "../../../components/mentorship_descr_input";

const SelfDescrPage = ({ location }) => {
  const onboarding =
    new URL(location.href).searchParams.get("state") == "onboarding";

  const example_text = [
    "Examples include: ",
    "I like a nice kick in the pants every now and then.",
    "I need a gentle soul.",
    "I need someone my age to keep me accountable (please include your age range).",
  ].join("\n");
  return (
    <>
      <div className="page">
        <OnboardingNavbar />
        <MentorshipDescrInput
          prompt_text="Tell us how you you like to be kept accountable to your goals."
          example_text={example_text}
          descr_text_attr="mentorship_other_descr"
          postSaveFunc={() => {
            navigate("/member/dashboard");
          }}
          has_prev_button={true}
          next_button_text={onboarding ? "Next" : "Save"}
          prev_button_text={onboarding ? "Previous" : "Cancel"}
          onClickPrev={
            onboarding
              ? () => {
                  navigate("/member/signup/profile_picture?state=onboarding");
                }
              : () => {
                  navigate("/member/dashboard");
                }
          }
        />
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <SelfDescrPage location={location} />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
